.qrCode {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Alinhar na horizontal */
    align-items: center;
    position: relative;

}

.qrCode-image {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}