@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap');

.App {
  width: 100vw;
  height: 100vh;
  background-color: #2d0960;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-form {
  margin-top: 0px;
}

h1, h3 {
  font-family: 'Montserrat', sans-serif;
  color: #9a0c72;
  text-align: center;
  margin: 0;
}

.centered-container {
  width: 100%;
  max-width: 1000px;
  height: 600px;
  background: #d7dced;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
  margin: 10px;
}

.colQrcode {
  flex: 1;
  margin: 0;
  padding: 0;
}

.qrCodeIcon {
  text-align: center;
  margin: 40px 20px;
}

/* Estilos adicionais para dispositivos menores, como smartphones */
@media (max-width: 600px) {
  .centered-container {
    width: 100vw;
    height: 90vh;
  }

  .row {
    flex-direction: column;
  }

  .col, .colQrcode {
    width: 100%;
  }

  .qrCodeIcon {
    margin: 20px 0;
  }

  .qrCodeIcon svg {
    width: 50%;
  }

  h1, h3 {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #9a0c72;
    text-align: center;
    margin: 0;
  }
}