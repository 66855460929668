@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.input-area {
    margin: 20px 0;
}

.input-area input {
    width: 100%;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.input-disabled {
    /* Adicione estilos específicos para inputs desabilitados aqui */
    background-color: #f2f2f2; /* Exemplo de alteração de cor de fundo */
    color: #999999; /* Exemplo de alteração de cor do texto */
    /* Outros estilos desejados */
  }

  @media (max-width: 600px) {
    .input-area {
        margin: 7px 0; /* Reduza a margem para dispositivos menores */
    }

    .input-area input {
        width: 90%;
        padding: 10px;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #fff;
    }
}