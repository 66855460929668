@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.button-area {
    display: flex;
    justify-content: center;
    /* Alinha horizontalmente no centro */
    align-items: center;
    /* Alinha verticalmente no centro */
}

.button {
    width: 87.733%;
    display: inline-block;
    padding: 6.4px 36px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #0056b3;
    /* Cor de fundo ao passar o mouse */
}

@media (max-width: 600px) {
    .button {
        width: 90%;
        display: inline-block;
        padding: 6.4px 20px;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}