  .error-alert {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff5733; /* Cor de fundo */
    color: #fff; /* Cor do texto */
    padding: 10px; /* Espaçamento interno */
    border-radius: 5px; /* Borda arredondada */
    z-index: 999; /* Garante que o alerta fique no topo */
    width: 20%; /* Largura máxima do alerta */
    text-align: center; /* Centralizar o texto */
    transition: opacity 0.5s ease; /* Transição suave de opacidade */
    opacity: 1; /* Inicialmente visível */
  }
