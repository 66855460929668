.webcam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.webcam {
    width: 400px;
    margin-top: 10px;
    border-radius: 15px;
    overflow: hidden; /* Evita que a imagem da webcam ultrapasse os limites do container */
}

.webcam-image {
    width: 100%;
    height: auto;
}

@media (max-width: 600px) {
    .webcam {
        width: 300px;
        display: grid;
    }

    .webcam-image {
        width: 100%;
        height: 90%;
        border-radius: 15px;
        transform: scaleX(-1);
    }
}


.registro-info {
    width: 400px;
    padding: 10px;
    margin-bottom: 30px;
    background-color: #f0f0f0;
    border-radius: 10px;
    text-align: center;
}

.registro-info h2 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
}

.registro-info p {
    margin: 5px 0;
    color: #555;
    font-size: 16px;
}

.registro-info .data-hora {
    font-size: 15px;
    font-weight: bold;
    color: #9a0c72;
}