/* RegistroLoja.css */
.register-screen {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .register-screen h1 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555555;
  }
  
  .input-group input,
  .input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .input-group input[type="password"] {
    font-family: sans-serif; /* Evita a quebra da fonte no campo de senha */
  }
  
  .input-group select {
    appearance: none; /* Remove a aparência padrão do select */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23555555" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>'); /* Ícone de seta */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 24px;
  }
  
  .input-group select:focus,
  .input-group input:focus {
    outline: none;
    border-color: #9a0c72;
  }
  
  /* Estilos para o botão */
  .input-group .button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    background-color: #9a0c72;
    cursor: pointer;
  }
  
  .input-group .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .input-group .button:hover {
    background-color: #7e085b;
  }

  .botao-registrar {
    background-color: #3ea3af; /* Verde */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
}

.botao-registrar:hover {
    background-color: #45a049; /* Tom mais escuro de verde no hover */
}

.botao-registrar:disabled {
    outline: none;
    border-color: black;
    background-color: #becec4; /* Cinza claro quando desabilitado */
    cursor: not-allowed; /* Altera o cursor para seta quando desabilitado */
}

