.logo {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
}

.logo-img {
    padding-top: 10px;
    width: 250px;
}