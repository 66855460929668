#btnValidarCPF {
    background-color: #4CAF50; /* Cor de fundo verde */
    border: none; /* Remove a borda */
    color: white; /* Cor do texto branco */
    padding: 10px 28px; /* Adiciona espaço interno ao redor do texto */
    text-align: center; /* Centraliza o texto */
    text-decoration: none; /* Remove a decoração do texto */
    display: inline-block; /* Exibe o botão como um bloco */
    font-size: 15px; /* Tamanho da fonte */
    margin: 4px 2px; /* Define as margens */
    cursor: pointer; /* Adiciona o cursor de apontar */
    border-radius: 10px; /* Adiciona bordas arredondadas */
  }
  
  #btnValidarCPF:hover {
    background-color: #45a049; /* Altera a cor de fundo quando o botão é passado com o mouse */
  }
  